@tailwind base;
@tailwind components;
@tailwind utilities;

html,body,#root,#root,#root>div {
    min-height: 100vh;
}
#root>div {
    align-items: stretch;
}
a:-webkit-any-link {
    text-decoration: none;
}

.unread {
    font-weight: bold;
    background-color: #eef5ff;

    .MuiTypography-root {
        font-weight: bold !important;
    }
}

.splide .splide__arrow { background-color: #686895; opacity: 1;} 
.splide .splide__arrow.splide__arrow--prev {left: -1rem;} 
.splide .splide__arrow.splide__arrow--next {right: -1rem;} 

@import "./savePDF.scss";
@import "./tablesList.scss";