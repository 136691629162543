
.mychip {
    justify-content: flex-start;
    svg {
       margin-left: -4px !important;
       width: 24px;
       height: 24px;
    }
    span {
        width: 100%;
        text-align: center;
    }
    cursor:pointer;
}