/********* PDF ***********/

@media print {
  .appbar {display:none}
  .main_navigation_panel {display: none;}

  .printImg {max-width: 285px;
    .imageControls {display: none;}
  }
  
  .responseExpert, .responseAi {
    .paperExpert, .paperAi {display: flex; flex-direction: row;}
    .infoExpert, .infoAi { 
      display: flex; flex-direction: column; align-items: inherit; max-width: 265px; border-right: 1px solid #686895;}
    .responseInfo, .responseInfoAi {
      .container {
        border-bottom: 1px solid #686895;
        .title { margin: inherit; padding: 16px; display: flex; flex-direction: row;
          .MuiChip-root { margin-top: 0; margin-left: 20px;}
        }
      }
    }
  }

  .dialogActions {
    display: none;
  }
}