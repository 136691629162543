.balance {

    &:hover .availablebudget {
     @apply flex;   
    }

    .availablebudget {
        @apply hidden absolute p-2;
        top: 104%;
        left: 10%;
        border-radius: 8px;
    }
}


.mychip {
    svg {
       margin-left: -4px !important;
       width: 30px;
       height: 30px;
    }
    cursor:pointer;
}

/* .appbar {
    @media print {
        display:none
    }
} */